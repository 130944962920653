<template>
  <v-dialog persistent no-click-animation width="700" v-model="statusOfDialog">
    <v-card>
      <v-card-title class="headline">{{ actionTitle }} {{ itemName }}</v-card-title>

      <v-card-text>
        {{ pretext }} {{ actionName }} <strong>{{ itemName }}</strong> {{ surtext }}</v-card-text
      >
      <div class="close-icon" @click="closeDialog">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          {{ leftButton }}
        </v-btn>
        <v-btn color="error" text @click="submitDelete">
          {{ rightButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      actionTitle: '',
    };
  },
  created() {
    this.upperCase();
  },
  methods: {
    upperCase() {
      this.actionTitle = this.actionName.charAt(0).toUpperCase() + this.actionName.slice(1);
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    submitDelete() {
      this.$emit('deleteItem');
    },
  },
  props: {
    pretext: {
      // mở đầu của thông báo, kiểu: " bạn có muốn "
      type: String,
      default: 'Bạn có chắc chắn',
    },
    actionName: {
      // tên hành động muốn thực hiện
      type: String,
      default: 'xóa',
    },
    itemName: {
      // tên item bị xóa
      type: String,
      default: '',
    },
    surtext: {
      // kết thúc thông báo,  kiểu " hay không"
      type: String,
      default: 'không ?',
    },
    statusOfDialog: {
      // trạng thái bật tắt của dialog
      type: Boolean,
      default: false,
    },
    leftButton: {
      // title của nút hủy bỏ hành động, nút bên trái
      type: String,
      default: 'Cancel',
    },
    rightButton: {
      // title của nút thực hiện hành động, nút bên phải
      type: String,
      default: 'Ok',
    },
  },
};
</script>

<style></style>
