<template>
  <div class="pa-2 form-component">
    <div class="d-flex justify-space-between">
      <label>Image</label>
      <p class="add-url primary--text">Add image from URL</p>
    </div>
    <div>
      <UploadOneImage :image="{ src: image }" @callback="getCallback" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getCallback(img) {
      this.$emit('setImage', img, this.index);
      console.log(this.index, img);
      this.image = img.src;
      // this.$parent.attributes.imageUrl = img.src;
    },
  },
  data() {
    return {
      image: '',
    };
  },
  props: {
    index: {
      type: Object,
      default: () => {},
    },
    src: {
      type: String,
      default: '',
    },
  },
  created() {
    this.image = this.src;
  },
};
</script>

<style>
.add-url:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
