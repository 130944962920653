import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function get(data) {
  return storeRequest.get('/stores/onlinestore/sizecharts', { params: { ...data } });
}
function create(data) {
  return storeRequest.post('/stores/onlinestore/sizecharts', data);
}
function removeById(ids) {
  return storeRequest.delete('/stores/onlinestore/sizecharts' + ids);
}
function getById(id) {
  return storeRequest.get('/stores/onlinestore/sizecharts/' + id);
}
function remove(ids) {
  return storeRequest.delete('/stores/onlinestore/sizecharts/' + ids);
}
function update(id, data) {
  return storeRequest.put('/stores/onlinestore/sizecharts/' + id, data);
}

export const sizechartApi = {
  create,
  update,
  getById,
  get,
  remove,
  removeById,
};
